import {
    Backdrop,
    CircularProgress,
    Grid,
    Typography
} from "@mui/material";
import classnames from "classnames";
import _ from "lodash";
import React from "react";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import {
    getParams, isLoggedInRequiredLink, navigateTo, removeParams, setParams
} from "../../utils/location";
import AppContainer from "../AppContainer";
import ChargeCard from "../ChargeCard";
import ExternalLink from "../ExternalLink";
import CheckmarkIcon from "../icons/CheckmarkSmall.svg";
import GiftNotificationBadge from "../GiftNotificationBadge";
import AppfrontIcon from "../icons/Appfront.svg";
import CreditCardIcon from "../icons/CreditCard.svg";
import GiftIcon from "../icons/Gift.svg";
import GiftCardIcon from "../icons/GiftCard.svg";
import ProfileIcon from "../icons/ProfileMenu.svg";
import ReorderIcon from "../icons/Reorder.svg";
import LABELS from "../labels";
import List from "../List";
import Loader from "../Loader";
import MyGiftCardsView from "../MyGiftCardsView";
import RechargeCardView from "../RechargeCardView";
import DeleteAccountDialog from "./delete-account-dialog";
import * as styles from "./index.module.scss";
import { PAYMENT_ID } from "../../api-services/constants";
import StripeProvider from "../StripeProvider";
import { ElementsConsumer } from "@stripe/react-stripe-js";
import withCaptcha from "../../hooks/withCaptcha";

const getAfterLogoutTarget = (params) => {
  const loggedInRequiredLink = isLoggedInRequiredLink(
    params.onCloseTarget ? params.onCloseTarget.replace("***", "?") : "/",
  );

  if (loggedInRequiredLink) {
    return "/";
  }
  if (params.onCloseTarget) {
    if (params.onCloseTarget === "/") {
      return "/";
    }
    return `/${params.onCloseTarget.replace("***", "?")}`;
  }
  return "/";
};

const WrappedRechargeCardView = (props) => {
  const { paymentTypeDetails, user, currency } = props;

  const singleLoadAmount = _.get(
    user,
    "loyaltyProfile.data.openChargeCardInstance.chargeCard.cardAmount.amount"
  );

  if (paymentTypeDetails.paymentType === PAYMENT_ID.STRIPE) {
    const options = {
      mode: "payment",
      amount: Math.floor(singleLoadAmount * 100),
      currency: currency.toLowerCase(),
      paymentMethodCreation: "manual",
    };

    return (
      <StripeProvider options={options} paymentTypeDetails={paymentTypeDetails}>
        <ElementsConsumer>
          {({ stripe, elements }) => (
            <RechargeCardView {...{ stripe, elements, ...props }} />
          )}
        </ElementsConsumer>
      </StripeProvider>
    );
  }

  return <RechargeCardView {...props} />;
};

export default class AccountMenuView extends React.Component {
  state = {
    openDeleteAccountDialog: false,
  };
  componentDidMount() {
    const {
      user: {
        registerPushNotifications: { pushIdentifier, showedPushApprovalPopUp },
      },
      pageContext: {
        businessAppConfiguration: { enableValutecGiftCards },
      },
      location
    } = this.props;
    this.props.loadLoyaltyProfile();
    if (enableValutecGiftCards) {
      this.props.getExternalGiftCards();
    }
    if (window.isNativeApp &&
      !pushIdentifier && !showedPushApprovalPopUp){
        window.postMessage(
          JSON.stringify({ type: "UPDATE_PUSH_NOTIFICATION_SETTINGS" }),
        );
        this.props.markShowedPushApprovalPopup();
      } 
      if (_.get(getParams(location), "deleteAccount" ) == "true"){
        this.setState({ openDeleteAccountDialog: true });
      }
  }

  componentDidUpdate(prevProps) {
    if (
      !prevProps.user.rechargeCard.sent &&
      this.props.user.rechargeCard.sent
    ) {
      this.props.loadLoyaltyProfile(true);
      navigateTo(removeParams(this.props.location, "rechargeCard"));
    }

    if (
      prevProps.user.deleteAccount.sent !== true &&
      this.props.user.deleteAccount.sent === true
    ) {
      const location = this.props;
      const params = getParams(location);
      const afterLogoutTarget = getAfterLogoutTarget(params);
      navigateTo(afterLogoutTarget);
    }

    if (
      Boolean(prevProps.user.deleteAccount.error) !== true &&
      Boolean(this.props.user.deleteAccount.error) === true
    ) {
      this.setState({ openDeleteAccountDialog: true });
    }
  }

  componentWillUnmount() {
    this.props.rechargeCardReset();
  }

  handleToggleChange = (toggleOn, permissions) => {
    const {
      user: {
        registerPushNotifications: { pushIdentifier, os },
      },
    } = this.props;
    if (!pushIdentifier) {
      console.log("pushIdentifier not found");
      window.postMessage(
        JSON.stringify({
          type: "PUSH_NOTIFICATIONS_TOGGLE_ON",
          payload: permissions,
        }),
      );
    } else {
      if (toggleOn) {
        if (_.some(permissions)){
          console.log("registerPushNotifications - toggle on");
          this.props.registerPushNotifications({
            ...permissions,
            pushIdentifier,
            os,
          });
        } else {
          console.log("pushIdentifier found but no permissions");
          window.postMessage(
            JSON.stringify({
              type: "PUSH_NOTIFICATIONS_TOGGLE_ON",
              payload: permissions,
            }),
          );
        }
      } else {
        console.log("registerPushNotifications - toggle off");
        this.props.registerPushNotifications({
          ...permissions,
          pushIdentifier,
          os,
        });
      }
    }
  };

  handleNewsToggleChange = (permissions) => () =>
    this.handleToggleChange(permissions.isNewsApproved, permissions);

  handlePurchaseToggleChange = (permissions) => () =>
    this.handleToggleChange(permissions.isPurchaseApproved, permissions);

  handleChangeLocation = (to) => () => {
    const { location, paymentTypeDetails } = this.props;
    this.props.rechargeCardReset();
    navigateTo(setParams(location, { [to]: true }));
    if (to === "rechargeCard") {
      const paymentTypeIdentifier = _.get(paymentTypeDetails, "paymentType");
      this.props.loadPaymentMethods(paymentTypeIdentifier);
    }
  };

  handleDeleteUserClicked = (e) => {
    this.setState({ openDeleteAccountDialog: true });
  };

  handleDeleteAccountCancelAndClose = (e) => {
    this.setState({ openDeleteAccountDialog: false });
  };

  handleDeleteAccountDoneAndClose = (e) => {
    const { location, deleteAccount } = this.props;
    const params = getParams(location);
    deleteAccount();
    this.setState({ openDeleteAccountDialog: false });
  };

  render() {
    const {
      T,
      appStyles = {},
      pageContext: {
        business: { currencySymbol, currency },
        businessAppConfiguration = {}
      },
      user,
      order,
      paymentTypeDetails,
      keyboardOpen,
      logout,
      location,
      setProfileInRegularMode,
      loadLoyaltyProfile,
      cashbackDiscounts,
      getExternalGiftCards,
      addExternalGiftCardToAccount,
      removeExternalGiftCardFromAccount,
      resetAddExternalGiftCardToAccount,
      subscriptionPolicies
    } = this.props;

    const {
      idRequired,
      hasCashback,
      loadCardEnabled,
      disableRewards,
      prePaymentEnabled,
      giftCardsEnabled,
      hasPointBank,
      enableFeedback,
      enableValutecGiftCards,
    } = businessAppConfiguration;

    const nativeMode =
      typeof window !== "undefined" ? window.isNativeApp : false;

    const {
      registerPushNotifications: {
        isNewsApproved,
        isPurchaseApproved,
        sending,
      },
      amountOfGiftsUnseen,
    } = user;

    const params = getParams(location);

    const rechargeCardMode = Boolean(params.rechargeCard);
    const hasSubscriptions = subscriptionPolicies && !_.isEmpty(subscriptionPolicies);

    const { links = {}, hiddenLinks = {}, rtl } = appStyles;

    const afterLogoutTarget = getAfterLogoutTarget(params);
    console.log({ afterLogoutTarget });

    return rechargeCardMode ? (
      <WrappedRechargeCardView
        T={T}
        appStyles={appStyles}
        user={this.props.user}
        location={location}
        idRequired={idRequired}
        keyboardOpen={keyboardOpen}
        currencySymbol={currencySymbol}
        currency={currency}
        paymentTypeDetails={paymentTypeDetails}
        rechargeCard={this.props.rechargeCard}
        loadLoyaltyProfile={loadLoyaltyProfile}
      />
    ) : (
      <>
        <AppContainer.Content appStyles={appStyles}>
          <List menu rtl style={{ ...appStyles.MyAccount }}>
            <List.Item
              rtl={appStyles.rtl}
              onClick={() => {
                setProfileInRegularMode();
              }}
              to={removeParams(
                setParams("/profile", params),
                "changePhoto",
              )}
            >
              <ProfileIcon className={styles.Icon} />
              {T("Profile")}
            </List.Item>
            <List.Item to={setParams("/history", params)}>
              <ReorderIcon className={styles.Icon} />
              {T("Order History")}
            </List.Item>
            {!disableRewards && (
              <List.Item
                to={setParams("/gifts", {
                  ...params,
                  backPath: "/my-account",
                })}
              >
                <GiftIcon className={styles.Icon} />
                {T("My Rewards")}
                <GiftNotificationBadge
                  appStyles={appStyles}
                  amountOfGiftsUnseen={amountOfGiftsUnseen}
                  classNames={classnames(
                    styles.GiftsNotification,
                    appStyles.rtl && styles.RTL,
                  )}
                  fill
                />
              </List.Item>
            )}
            {giftCardsEnabled && (
              <List.Item
                to={
                  nativeMode
                    ? setParams("/contacts", {
                        ...params,
                        fromGiftCard: true,
                      })
                    : setParams("/gift-card", params)
                }
                onClick={this.props.resetGiftCard}
              >
                <GiftCardIcon className={styles.Icon} />
                {T("Send a Gift Card")}
              </List.Item>
            )}
            {hasSubscriptions && (
              <List.Item
                to={
                  setParams("/subscriptions", params)
                }
                onClick={this.props.resetSubscription}
              >
                <CheckmarkIcon className={styles.Icon} />
                {T("Subscribe")}
              </List.Item>
            )}
            <List.Item
              to={setParams("/payment-methods", {
                ...params,
                backPath: "/my-account",
              })}
            >
              <CreditCardIcon className={styles.Icon} />
              {T("Payment Methods")}
            </List.Item>
            {enableFeedback && (
              <>
                <List.Separator />
                <ExternalLink
                  link={_.get(appStyles.contactUsAlternative, "href")}
                  fallbackLink={setParams("/contact-us", {
                    ...params,
                    backPath: "/my-account",
                  })}
                  appStyles={appStyles}
                >
                  <List.Item>
                    {T(
                      _.get(
                        appStyles,
                        "contactUsAlternative.label",
                        LABELS.CONTACT_US,
                      ),
                    )}
                  </List.Item>
                </ExternalLink>
              </>
            )}
            <List.Separator />
            {nativeMode &&
              (sending ? (
                <div
                  style={{
                    display: "flex",
                    flexFlow: "column",
                    alignItems: "center",
                    margin: 15,
                  }}
                >
                  <Loader small appStyles={appStyles} />
                  <List.Item>{T("Updating Notifications")}</List.Item>
                  <List.Separator />
                </div>
              ) : (
                <div>
                  <div>
                    <List.Item>
                      {T("Receive News Notifications")}
                      <Toggle
                        className={classnames(styles.Toggle, rtl && styles.RTL)}
                        icons={false}
                        checked={isNewsApproved}
                        onChange={this.handleNewsToggleChange({
                          isNewsApproved: !isNewsApproved,
                          isPurchaseApproved: isPurchaseApproved,
                        })}
                      />
                    </List.Item>
                    <List.Item>
                      {T("Receive Purchase Notifications")}
                      <Toggle
                        className={classnames(styles.Toggle, rtl && styles.RTL)}
                        icons={false}
                        checked={isPurchaseApproved}
                        onChange={this.handlePurchaseToggleChange({
                          isNewsApproved: isNewsApproved,
                          isPurchaseApproved: !isPurchaseApproved,
                        })}
                      />
                    </List.Item>
                  </div>
                  <List.Separator />
                </div>
              ))}

            {!appStyles.hideChargeCard &&
              (hasCashback || prePaymentEnabled || hasPointBank) && (
                <div>
                  <ChargeCard
                    appStyles={appStyles}
                    T={T}
                    hasCashback={hasCashback}
                    hasChargeCard={loadCardEnabled}
                    hasPointBank={hasPointBank}
                    user={user}
                    order={order}
                    onError={loadLoyaltyProfile}
                    onRecharge={this.handleChangeLocation("rechargeCard")}
                    currencySymbol={currencySymbol}
                    cashbackDiscounts={cashbackDiscounts}
                  />
                  <List.Separator />
                </div>
              )}
            {enableValutecGiftCards && (
              <div>
                <MyGiftCardsView
                  appStyles={appStyles}
                  T={T}
                  currencySymbol={currencySymbol}
                  addExternalGiftCardToAccount={addExternalGiftCardToAccount}
                  removeExternalGiftCardFromAccount={
                    removeExternalGiftCardFromAccount
                  }
                  getExternalGiftCards={getExternalGiftCards}
                  resetAddExternalGiftCardToAccount={
                    resetAddExternalGiftCardToAccount
                  }
                  user={user}
                  businessAppConfiguration={businessAppConfiguration}
                />
                <List.Separator />
              </div>
            )}
            {links.nutrition && (
              <ExternalLink link={links.nutrition} appStyles={appStyles}>
                <List.Item>{T("Nutrition Facts")}</List.Item>
              </ExternalLink>
            )}
            {_.map(appStyles.menuEmbed, (embeded) => (
              <List.Item style={{ flexDirection: "column" }}>
                <div
                  style={{
                    marginBottom: "1.3rem",
                    color: appStyles.accentColor,
                    fontSize: "1.3rem",
                  }}
                >
                  {embeded.label}
                </div>
                <iframe
                  sandbox="allow-same-origin allow-scripts "
                  {...embeded.attributes}
                />
              </List.Item>
            ))}
            {!hiddenLinks.about && (
              <ExternalLink
                link={links.about}
                fallbackLink={setParams("/about", {
                  ...params,
                  backPath: "/my-account",
                })}
                appStyles={appStyles}
              >
                <List.Item>{T("About")}</List.Item>
              </ExternalLink>
            )}
            {links.gdpr && (
              <ExternalLink link={links.gdpr} appStyles={appStyles}>
                <List.Item>{T("GDPR Request Form")}</List.Item>
              </ExternalLink>
            )}

            {!hiddenLinks.privacy && (
              <ExternalLink
                link={links.privacy}
                fallbackLink={setParams("/privacy-policy", {
                  ...params,
                  backPath: "/my-account",
                })}
                appStyles={appStyles}
              >
                <List.Item>{T("Privacy Policy")}</List.Item>
              </ExternalLink>
            )}
            {!hiddenLinks.tos && (
              <ExternalLink
                link={links.tos}
                fallbackLink={setParams("/tos", {
                  ...params,
                  backPath: "/my-account",
                })}
                appStyles={appStyles}
              >
                <List.Item>{T("Terms of Service")}</List.Item>
              </ExternalLink>
            )}
            <List.Separator />
            <List.Item onClick={this.handleDeleteUserClicked}>
              {T("Delete My Account Forever")}
            </List.Item>
            <List.Separator />
            <List.Item onClick={logout} to={afterLogoutTarget}>
              {T("Logout")}
            </List.Item>
            <List.Separator />
            <ExternalLink
              link="https://appfront.ai/?src=pwrdby"
              title="Online Ordering, Loyalty, Rewards & CRM"
              appStyles={appStyles}
            >
              <List.Item centered noBorder>
                <span
                  className={classnames(styles.PoweredBy, rtl && styles.RTL)}
                >
                  Powered by <AppfrontIcon />
                </span>
              </List.Item>
            </ExternalLink>
          </List>
        </AppContainer.Content>
        <DeleteAccountDialog
          T={T}
          open={this.state.openDeleteAccountDialog}
          onDoneAndClose={this.handleDeleteAccountDoneAndClose}
          onCancelAndClose={this.handleDeleteAccountCancelAndClose}
          error={user.deleteAccount.error}
        />
        <Backdrop
          open={user.deleteAccount.sending}
          style={{ zIndex: 1, color: "white" }}
        >
          <Grid container direction="column" alignItems="center" spacing={2}>
            <Grid item>
              <CircularProgress color="inherit" />
            </Grid>
            <Grid item>
              <Typography color="inherit" variant="subtitle1">
                {T("Deleting your Account...")}
              </Typography>
            </Grid>
          </Grid>
        </Backdrop>
      </>
    );
  }
}

export const AccountMenuViewWithRecaptcha = withCaptcha(AccountMenuView);
